import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { motion } from "framer-motion"
import logo from "../images/logo.svg"
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"
import insta from "../images/insta.png"
import opensea from "../images/opensea.png"
import discord from "../images/discord.png"
import twitter from "../images/twitter.png"
import { FramerNavigation } from "./FramerNavigation.js"

class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        hasScrolled: false,
        isMobile: false,
        menuOpen: false,
        isNotHomePage: false,
        framerClick: false
    }
}



componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleWidth);
    window.addEventListener('innerWidth', this.handleInnerWidth);


    if (window.pageYOffset > 50) {
        this.setState({ hasScrolled: true })
    } else {
        this.setState({ hasScrolled: false })
    };


}

handleMenuclick() {
    this.setState({ menuOpen: !this.state.menuOpen });
}

handleClick() {
    this.setState({ menuOpen: false, framerClick: true});
}



// handleURL = (event) => {
//     const currentURL = '/'

//     if (currentURL === '/') {
//         this.setState({isNotHomePage: false}) 
//     } else {
//         this.setState({isNotHomePage: true})
//     }
// }

handleInnerWidth = (event) => {
    const windowWidth = window.innerWidth

    if (windowWidth < 1085) {
        this.setState({ isMobile: true })
    } else {
        this.setState({ isMobile: false })
    }

}

handleWidth = (event) => {
    const windowWidth = window.innerWidth

    if (windowWidth < 1085) {
        this.setState({ isMobile: true })
    } else {
        this.setState({ isMobile: false })
    }
}

handleScroll = (event) => {
    const scrollTop = window.pageYOffset

    if (scrollTop > 1) {
        this.setState({ hasScrolled: true })
    } else {
        this.setState({ hasScrolled: false })
    }
}

  render() {
  return (
    <>
      <FramerNavigation className="on-mobile" className={this.state.hasScrolled || this.state.isMobile || this.state.isNotHomePage ? 'header-scrolled' : 'header-scrolled'} className={this.state.framerClick ? 'framer-open' : 'framer-closed'}/> 
      <Navbar collapseOnSelect expand="lg" variant="light" sticky="top" className={this.state.hasScrolled || this.state.isMobile || this.state.isNotHomePage ? 'header header-scrolled' : 'header'}>
        <Navbar.Brand href="/">
          <img src={logo} alt="Paperhands Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{ justifyContent: "flex-start" }}
        >
          
          <Nav>
         
          <Nav.Link href="/#roadmap">Roadmap</Nav.Link>
            <Nav.Link href="/#team">Team</Nav.Link>
            <Nav.Link href="/#faqs">FAQs</Nav.Link>
            <Nav.Link href="https://mint.paperhands.game/staking" target="_blank">Staking</Nav.Link>
            {/* <Nav.Link to="#" >Staking</Nav.Link> */}

          </Nav>
          
        </Navbar.Collapse>
        <Nav className="button-nav">
        <Link href="https://twitter.com/PaperHandsGame" target="_blank" style={{position: "relative", zIndex: "600"}}><img src={twitter} alt="Twitter" style={{zIndex: "2", position: "relative"}}/></Link>
        <Link href="https://discord.gg/ZahdBNaqGv" target="_blank" style={{marginLeft:"-70px", position: "relative", zIndex: "500"}}><img src={discord}alt="Discord"  style={{zIndex: "1"}} /></Link>
        <Link href="https://opensea.io/collection/paperhandsgame" target="_blank" style={{marginLeft:"-70px"}}><img src={opensea} alt="OpenSea"  style={{zIndex: "1"}} /></Link>
        <Link to="https://mint.paperhands.game/" > <Button>
           Mint Now
          </Button></Link> 
        </Nav>
      </Navbar>
    </>
  )
}
}

export default Navigation
