import * as React from "react"
import { useRef } from "react"
import { motion, useCycle } from "framer-motion"
import { useDimensions } from "./use-dimensions"
import { MenuToggle } from "./MenuToggle"
import { FramerNav } from "./FramerNav"

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 88% 3.8%)`,
    opacity: 1,
    marginTop: "-10px",
    display: "block",
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
      opacity: 1,
    },
  }),
  closed: {
    clipPath: "circle(30px at 88% 3.8%)",
    opacity: 0,
    display: "none",
    marginTop: "-10px",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,


    },
  },
}

const mainNav = {
  open: (height = 1000) => ({

  }),
  closed: {
    height: 0
  }
}

export const FramerNavigation = () => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const { height } = useDimensions(containerRef)

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
      className={isOpen ? "framer-nav framerOpen" : "framer-nav framerClosed"}
    >
      <motion.div className="background" variants={sidebar} />
      <FramerNav />
      <MenuToggle toggle={() => toggleOpen()} />
    </motion.nav>
  )
}
