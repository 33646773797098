import * as React from "react"
import { motion } from "framer-motion"
import { MenuItem } from "./MenuItem"
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"
const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    zIndex: 2000,
    height: 0,
    display: "block"
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
    zIndex: -1,
    height: 100,
    display: "none"
  },
}

export const FramerNav = () => (
  <motion.ul variants={variants}>
    {itemIds.map(i => (
      <MenuItem i={i} key={i} />
    ))}
  </motion.ul>
)

const itemIds = [0, 1, 2, 3, 4, 5, 6]
