/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import React from "react"
 import { Link } from "gatsby"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import logo from "../images/logo.svg"
 
 import Header from "./Header.js"
 import "./layout.css"
 import { Row, Col, Container } from "react-bootstrap"
import twitter from "../images/twitter_logo.svg"
import discord from "../images/discord_logo.svg"
import opensea from "../images/opensea_logo.svg"
import { Helmet } from "react-helmet"
 const Layout = ({ children }) => {
   const isBrowser = typeof window !== "undefined"
 
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
   return (
     <>
 <Helmet><link rel="stylesheet" href="https://use.typekit.net/ior6ymj.css" />
 <meta name="icon" href="https://paperhands.game/images/favicon.png" />
 <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
 </Helmet>
       <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
       <div
         style={{
           margin: `0 auto`,
         }}
       >
         <main>{children}</main>
         {isBrowser && window.location.pathname !== "/signup" && (
           <footer style={{position: "relative", zIndex:"0"}}>
            
             <Container className="pb-3 mobile-pt-10 mobile-pb-3 mobile-mt-m10">
               <Row> 
                 <Col xs={12} >
                <Link to="/"> <img src={logo} alt="Paperhands" className="footer-logo"/></Link>
                   <h2>Join the community</h2>
                 </Col>
               </Row>
               <Row> 
                 <Col xs={12} >
                   <div className="footer-socials">
                   <a href="https://twitter.com/PaperHandsGame" target="_blank"> <img src={twitter} /></a>
                   <a href="https://discord.gg/ZahdBNaqGv" target="_blank"> <img src={discord} /></a>
                   {/* <a href="https://opensea.io/collection/paperhandsgame" target="_blank"> <img src={opensea} /></a> */}
                   
                       </div>
                 </Col>
               </Row>
               <Row> 
                 <Col xs={12} >
                  
                   <div className="footer-socials">
                     <p className="copyright-text">©️ 2022 Paper Labs LLC<br />
All Rights Reserved</p>
                      <Link to="/privacy">Privacy Policy</Link>
                      <Link to="/terms">Terms &#38; Conditions</Link>
                       </div>
                 </Col>
               </Row>
             </Container>
           </footer>
         )}
       </div>
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 