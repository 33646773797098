import * as React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import {
  Container, 
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap"

const variants = {
  open: {
    y: 0,
    zIndex: 2000,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    zIndex: -1,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const colors = ["#FFF"]
const names = [
  "Roadmap",
  "FAQs",
  "Enter the dashboard",
  "Staking",
  "Official OpenSea",
  "Mint now"
]
const url = [
  "/#roadmap",
  "/#faqs",
  "https://dashboard.paperhandsnft.com/dashboard",
  "https://mint.paperhands.game/staking",
  "https://opensea.io/collection/paperhandsgame",
  "https://mint.paperhands.game"
]
const classes = [
  "item1",
  "item2",
  "item3",
  "item4",
  "item5",
  "sign-in-button",
]

export const MenuItem = ({ i }) => {
  const style = { color: "white", textAlign: "center" }
  return (
    <motion.li
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      {/* <div className="icon-placeholder" style={style} /> */}
      <Link href={url[i]} style={style} className={classes[i]}>
        {names[i]}
      </Link>
      {/* <div className="text-placeholder" style={style} /> */}
    </motion.li>
  )
}
